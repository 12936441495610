import * as React from "react";
import Container from "../theme/container";
import Heading from "../theme/heading";
import Button from "../theme/button";

import "../styles.css"

const Contact = ({ heading, buttonPrompt, prompt, phone, email, location }) => {
    return (
        <section className="callToActionRoot" id="contact" >
            <Container>
                <Heading as="h1" align="center">{heading}</Heading>
                <center>
                    <p>{prompt}</p>
                    <Button href="/consultation" text={buttonPrompt} />
                    <p></p>
                    <Button variant="secondary" href="mail:contact@mfksecurity.com" text={email} />
                    <Button variant="secondary" href="tel:6479017623" text={["Phone: ", phone].join("")} />
                    <p>{location}</p>
                </center>
                <div>
                </div>
            </Container>
        </section>
    );
}


export default Contact;
// EOF