import * as React from "react"
import Layout from "../components/layout";

// Styles
import "../styles.css"

import Content from "../content";

// Sections
import Contact from "../sections/contact";
import Container from "../theme/container";
import Heading from "../theme/heading";
import Button from "../theme/button";

const PageNotFound404 = () => {
    return (
        <>
            <Layout contact={Content.Contact}>
                <section>
                    <Container>
                        <Heading as="h1" align="center">404 - Page Not Found</Heading>
                        <center>
                            <Button href="/" text="Redirect to Home Page" />
                        </center>
                    </Container>
                </section>

                <Contact
                    heading={Content.Contact.heading}
                    prompt={Content.Contact.prompt}
                    buttonPrompt={Content.Contact.buttonPrompt}
                    email={Content.Contact.email}
                    phone={Content.Contact.phone}
                    location={Content.Contact.location}
                />
            </Layout>
        </>
    );
}

export default PageNotFound404;